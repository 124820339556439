import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { API_END_POINT } from "../../../utils/Constants";

const User = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
   const res = await axios.get(`${API_END_POINT}/users`)
        if (res.status === 200) {
          setUsers(res.data);
        }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

    
  const handleDelete = (e) => {
    let id = e.currentTarget.value;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`${API_END_POINT}/users/${id}`).then(function(response) {
          if(response.status === 200){
            fetchUsers();
            swal({timer: 2000, title: "Success", text: "User has been Deleted successfully", icon:"success", button: false});
          }
        }).catch(function (error) {
          console.log(error);
        })
      } else {
        swal({timer: 2000, text: "User data is safe", button: false});
      }
    });

  };

  return (
    <section>
      <div className="container p-5 my-5 border">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div className="row">
                <div className="col-6">
                  <strong>Users</strong>
                </div>
                <div className="col-6 text-end">
                  <Link to="/users/create" className="btn btn-sm btn-success">Create user</Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table className="table table-borderd">
              <thead>
                <tr>
                  <th>Sr#:</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={user.id}>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.status === 1 ? (
                          <i className="bg-success p-1 rounded text-white">
                            Active
                          </i>
                        ) : (
                          "Blocked"
                        )}
                      </td>
                      <td>
                      <Link to={"/users/edit/"+user.id} className="btn btn-sm btn-primary">Edit</Link>
                        <button
                          onClick={handleDelete}
                          value={user.id}
                          className="btn btn-sm btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default User;
