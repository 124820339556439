
import React, {useState, useEffect} from "react";
import "./Create.css";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { API_END_POINT } from "../../../utils/Constants";

const Edit = (props) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    gender: "male",
    city: "",
    dob: "",
    status: 1,

  });
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const history = useNavigate();

  const { id } = useParams();

  const fetchUsers = async () => {
    const res = await axios.get(`${API_END_POINT}/users/${id}`);
    if (res.status === 200) {
        setUser(res.data);
        setIsPending(false);
    }
}

useEffect(() => {
    fetchUsers(); 
}, []);

  const userData = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const validateInput = (user) => {
    let errors = {};
    for (const key in user) {
      if (!user[key]) {
        errors = { ...errors, [key]: `${key} field is required` };
      }
    }
    return errors;
  };

  const handleSubmit = (e) => {
    setErrors(validateInput(user));

    if (Object.keys(validateInput(user)).length !== 0) {
      return;
    }
    setIsSubmit(true);
    axios
      .put(`${API_END_POINT}/users/${user.id}`, user)
      .then(function (response) {
        setIsSubmit(false);
        if(response) {
            swal({timer: 2000, title: "Success", text: "User has been updated successfully", icon:"success", button: false});
            history('/users');
        }
      })
      .catch(function (error) {
        setIsSubmit(false);
        console.log(error);
      });
  };

  return (
    <section style={{ backgroundColor: "rgb(221 221 221)" }}>

      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-8">
            <div
              className="card shadow-2-strong"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5">
                <div className="row">
                    <div className="col-6">
                  <h6 className="mb-5">Edit user</h6>
                    </div>
                    <div className="col-6 text-end">
                {isPending && <strong className="text-success">Loading...</strong>}

                    </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Name <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        value={user.name}
                        onChange={userData}
                      />
                      {errors.name && (
                        <p className="text-danger fw-bold">{errors.name}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        name="email"
                        type="email"
                        id="email"
                        className="form-control"
                        value={user.email}
                        onChange={userData}
                      />
                      {errors.email && (
                        <p className="text-danger fw-bold">{errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="city">
                        City <span>*</span>
                      </label>
                      <select
                        name="city"
                        value={user.city}
                        className="form-control"
                        onChange={userData}
                        id="city"
                      >
                        <option value="" disabled>
                          {" "}
                          Select any option
                        </option>
                        <option value="karachi"> Karachi</option>
                        <option value="islamabad"> Islamabad</option>
                      </select>
                      {errors.city && (
                        <p className="text-danger fw-bold">{errors.city}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="dob">
                        Data of birth <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        id="dob"
                        value={user.dob}
                        onChange={userData}
                      />
                      {errors.dob && (
                        <p className="text-danger fw-bold">{errors.dob}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="form-group radio_group">
                      <label className="form-label" htmlFor="gender">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        onChange={userData}
                        checked={user.gender === "male"}
                      />
                      <strong>Male</strong>
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        onChange={userData}
                        checked={user.gender === "female"}
                      />
                      <strong>Female</strong>
                      <input
                        type="radio"
                        name="gender"
                        value="other"
                        onChange={userData}
                        checked={user.gender === "other"}
                      />
                      <strong>Other</strong>
                      {errors.gender && (
                        <p className="text-danger fw-bold">{errors.gender}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="text-end">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleSubmit} disabled={isSubmit}> {isSubmit ? 'Updating...' : 'Update'} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit;
