import React, { useState } from "react";
import swal from "sweetalert";

const Contact = () => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isProcessing, setProcessing] = useState(false);

  const handleSubmit = () => {
    let errors = "";
    if (name.trim() === "") {
      errors += `Name field is required \n`;
    }
    if (title.trim() === "") {
      errors += "Title field is required \n";
    }
    if (body.trim() === "") {
      errors += "Message field is required \n";
    }

    if (errors.length > 0) {
        console.log('here');
      return swal({ title: "Required!", text: errors, icon: "error" });
    }

    setProcessing(true);
    fetch("https://jsonplaceholder.typicode.com/posts", {
      method: "POST",
      body: JSON.stringify({
        title,
        body,
        userId: 1,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setProcessing(false);
        if (Object.keys(response).length === 0) {
          return swal(
            "Problem",
            "There is some problem while submitting your request",
            "error"
          );
        }
        setName("");
        setTitle("");
        setBody("");
        swal({
          timer: 2000,
          title: "Success",
          text: "Your form has been submitted successfully",
          icon: "success",
          button: false,
        });
      });
  };

  return (
    <section style={{ backgroundColor: "rgb(221 221 221)" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-8">
            <div
              className="card shadow-2-strong"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5">
                <div className="row text-center">
                  <h3 className="mb-5">Contact Us</h3>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="typeEmailX-2">
                        Name:{" "}
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        id="typeEmailX-2"
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="title">
                        Title
                      </label>
                      <input
                        type="text"
                        id="title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        className="form-control form-control-lg"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    value={body}
                    className="form-control"
                    onChange={(e) => setBody(e.target.value)}
                  />
                </div>
                <div className="text-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    type="button"
                  >
                    {isProcessing ? "Processing..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
