import "./App.css";
import Header from "./components/layout/header/Header";
import Navbar from "./components/layout/navbar/Navbar";
import Home from "./components/pages/home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/pages/login/Login";
import Footer from "./components/layout/footer/Footer";
import Contact from "./components/pages/contact/Contact";
import Dashboard from "./components/pages/dashboard/Dashboard";
import { useContext } from "react";
import { AuthContext } from "./utils/Constants";
import Page404 from "./components/pages/Page404";
import User from "./components/modules/users/Index";
import Create from "./components/modules/users/Create";
import Edit from "./components/modules/users/Edit";
import Profile from "./components/pages/profile/Profile";

function App() {
  const { user } = useContext(AuthContext);
  return (
    <div>
      {user.isLoggedIn ? (
        <Router>
          <Navbar />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/users" element={<User />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/users/create" element={<Create />} />
            <Route path="/users/edit/:id" element={<Edit />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
          <Footer />
        </Router>
      ) : (
        <Router>
          <Navbar />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
