import React from "react";

const Page404 = () => {  

//   const centerImage = {
//     backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/404.jpg)`,
//     height: "500px",
//     backgroundPosition: "relative",
//     backgroundRepeat: "no-repeat",
//     backgrounSize: "cover"
//   };

  return (

  <div className="container">
  <img src={`${process.env.PUBLIC_URL}/assets/images/404.jpg`} alt="Snow" style={{width: '100%' }} />
</div>
);
}

export default Page404;
