import React from "react";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <section className="vh-100">
      <div className="container p-5 my-5 border">
        <h1>Dashboard</h1>
        <div className="card">
          <div className="card-header">All contents</div>
          <div className="card-body">
            <h3>Title</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis
              assumenda voluptatum possimus cumque odio quod exercitationem
              adipisci, laborum quasi accusamus? Sapiente natus doloribus
              assumenda numquam corrupti sint beatae facere debitis!
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis
              assumenda voluptatum possimus cumque odio quod exercitationem
              adipisci, laborum quasi accusamus? Sapiente natus doloribus
              assumenda numquam corrupti sint beatae facere debitis!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
