import React, { useState, useContext }from 'react';
import { AuthContext } from '../../../utils/Constants';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const {setUser} = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const history = useNavigate();

  const handleAuth = () => {
    let errorBag = [];
    if(email === '') {
      errorBag = [...errorBag, 'Email is required'];
    }
    if( password === '') {
      errorBag = [...errorBag, 'Password is required'];
    }
    if(!(email === 'test@test.com') || !(password === 'test123')) {
      errorBag = [...errorBag, 'Invalid credentials'];
    }

    if(errorBag.length > 0) {
      setErrors(errorBag);
      return;
    }

    sessionStorage.setItem('email', email);  
    sessionStorage.setItem('password', password);  
    sessionStorage.setItem('isLoggedIn', true);
    setUser({email,password, isLoggedIn: true}); 
      history('/dashboard');
  }

  return (
    <section  style={{backgroundColor: 'rgb(221 221 221)'}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong" style={{'borderRadius': "1rem"}} >
          <div className="card-body p-5 text-center">

            <h3 className="mb-5">Login</h3>
            <div className="form-outline mb-4">
            <label className="form-label" htmlFor="typeEmailX-2">Email</label>
              <input type="email" onChange={e => setEmail(e.target.value)}  value={email} id="typeEmailX-2" className="form-control form-control-lg" />
            </div>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="typePasswordX-2">Password</label>
              <input type="password" id="typePasswordX-2" onChange={e => setPassword(e.target.value)}  value={password} className="form-control form-control-lg" />
            </div>
            <button className="btn btn-primary btn-lg btn-block" onClick={handleAuth}  type="button">Login</button>
            {
              (errors.length > 0 ) &&
              errors.map((error, index) => {
                return <p key={index}  style={{color:'red'}}>{error}</p>
              })
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Login