import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
<footer className="bg-light text-center text-lg-start">
  <div className="text-center p-3 footer">
    <p>All rights reserved by abc.</p>
  </div>
</footer>
  )
}

export default Footer