import React from "react";
import './Header.css';

const Header = () => {
  return (
    <header className="App-header">
      <h3>Header</h3>
    </header>
  );
};

export default Header;
