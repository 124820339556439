import React, { useEffect, useState } from "react";
import { fetchDataFromApi } from "../utils/Helpers";
import { AuthContext } from "../utils/Constants";

const Provider = (props) => {
  const [user, setUser] = useState({});
  const [posts, setPosts] = useState([]);
  
  useEffect( () => {
    let email = sessionStorage.getItem("email");
    let password = sessionStorage.getItem("password");
    if(!(email === 'test@test.com') || !(password === 'test123')) {
        setUser({});
        sessionStorage.clear();
        return;
      } 
    setUser({email, password, isLoggedIn:true});

  let getPosts = async () => {
    let response = await fetchDataFromApi('https://jsonplaceholder.typicode.com/posts');
    setPosts(response.data);
  }
  getPosts();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, posts }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default Provider;

