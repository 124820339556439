import React, { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../utils/Constants";

const Profile = () => {
  const { user, posts } = useContext(AuthContext);

  return (
    <section>
      <div className="container p-5 my-5 border">
        <div className="row">
        <div className="col-3">
        <h1>User profile</h1>
        <div className="card">
          <div className="card-header">User</div>
          <div className="card-body">
            <p>
              <strong>Email: {user.email}</strong>
            </p>
            <p>
              <strong>Password: {user.password}</strong>
            </p>
            <p>
              <strong>
                {user.isLoggedIn ? <i className="green">Logged in</i> : "Not"}
              </strong>
            </p>
          </div>
        </div>
        </div>
        <div className="col-9">
        <h3>All posts</h3>
            <div>       
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Id: </th>
                    <th>Title: </th>
                    <th>Body: </th>
                  </tr>
                </thead>
                <tbody >
                  {posts.map((post) => {
                    return (
                      <tr>
                        <td>{post.id}</td>
                        <td>{post.title}</td>
                        <td>{post.body}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
