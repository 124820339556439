import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <section className="vh-100">
      <div className="container p-5 my-5 border">
        <h1>Home page</h1>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod,
          eligendi libero maiores obcaecati quaerat ut unde doloribus non
          facere? Nihil laborum sed quia placeat, error harum architecto omnis
          nobis maiores.
        </p>
      </div>
    </section>
  );
};

export default Home;
